import * as React from 'react';
import styled from '@emotion/styled';

import { colorValues } from '.';
import { getGridRows } from '../utils';
import { Layout } from '../components/layout';
import { Rectangle } from '../components/rectangle';
import { useWindowSize } from '../hooks/useWindowSize';

const Grid = styled.div<{ stops: number }>`
  display: grid;
  grid-template-columns: ${({ stops: stops }) => `repeat(${stops}, 1fr)`};
  grid-template-rows: ${({ stops: stops }) => `repeat(${stops}, 1fr)`};
  gap: 0px 0px;
  grid-auto-flow: row;
`;

const GridPage = () => {
  const { width = 20, height = 20 } = useWindowSize();

  const stops = 10;
  const rWidth = `${width / stops}px`;
  const rHeight = `${height / stops}px`;
  const gridRows = getGridRows(colorValues, 10);

  return (
    <Layout className="grid" title="Grid">
      <Grid stops={stops}>
        {gridRows.map(colors =>
          colors.map(c => (
            <Rectangle
              width={rWidth}
              height={rHeight}
              color={c.toRgbString()}
            />
          )),
        )}
      </Grid>
    </Layout>
  );
};

export default GridPage;
